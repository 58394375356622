import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AppRouter } from "@core/helper/app-router";

import { AuthService } from "@shared/services/auth.service";
import { LanguageService } from "@shared/services/language.service";

@Injectable({ providedIn: "root" })
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    public languageService: LanguageService,
    private appRouter: AppRouter
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(
      [this.appRouter.getLang(state.url) + "/account/login"],
      { queryParams: { returnUrl: this.appRouter.getPath(state.url) } }
    );
    return false;
  }
}
